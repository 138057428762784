import React from 'react'
import Link from 'gatsby-link'
import Projects from '../components/Projects'
import Layout from "../layouts/index.js"

const IndexPage = () => (
  <Layout>
    <div>
      <Projects/>
    </div>
  </Layout>
)

export default IndexPage
